<template>
  <div class="box-list">
    <div class="box-list-header">
      {{ attributeOffer.offerName }}    
      <span v-if="attributeOffer.isDistanceOportunityAllowed && attributeOffer.isPresentialOportunityAllowed">
        <Icon name="fal fa-globe-americas icon-attribute" title="Pode atuar à distância" />
        <Icon name="fal fa-users  icon-attribute" title="Pode atuar presencial" />
      </span>        
      <Icon name="fal fa-globe-americas icon-attribute" v-else-if="attributeOffer.isDistanceOportunityAllowed" title="Atuação à distância" />
      <Icon name="fal fa-users  icon-attribute" v-else-if="attributeOffer.isPresentialOportunityAllowed" title="Atuação presencial" />
      <Icon name="fal fa-calendar icon-attribute" v-if="attributeOffer.volunteeringType.name == 'Pontual'" title="Vaga Pontual" />
      <Icon name="fal fa-sync icon-attribute" v-if="attributeOffer.volunteeringType.name == 'Recorrente'" title="Vaga Recorrente" />
    </div>
    <div class="image" v-if="attributeOffer.file">
      <img :src="attributeOffer.file.path" alt />
    </div>
    <div class="box-list-body">
      <div class="mb-3" v-if="attributeOffer.function">
        <h6>função</h6>
        <p>{{ attributeOffer.function }}</p>
      </div>
      <div class="mb-3" v-if="attributeOffer.prerequisites">
        <h6>pré-requisitos</h6>
        <p v-html="attributeOffer.preRequisites"></p>
      </div>
      <template
        v-if="attributeOffer.volunteeringType.name == 'Recorrente'"
        v-for="recurrentType in attributeOffer.volunteeringRecurrentTypes"
      >
        <div class="hours" :key="recurrentType.id">
          <div class="icon mr-3">
            <Icon name="fal fa-sync" size="24" />
          </div>
          <div>
            <h5>{{ getRecurrentWeekHoursText(recurrentType) }}</h5>
            <h3>{{ getRecurrentWeekDayText(recurrentType) }}</h3>
            <p>{{ getRecurrentWeekTimeText(recurrentType) }}</p>
          </div>
        </div>
        <div class="subs-box mt-3">
          <b>Voluntáriado até: {{ getRecurrentLimitDateText(recurrentType) }}</b>
        </div>
      </template>
      <div
        class="hours align-center mb-2"
        v-if="attributeOffer.volunteeringType.name == 'Pontual'"
        v-for="pontualType in attributeOffer.volunteeringPontualTypes"
        :key="pontualType.id"
      >
        <Icon name="fas fa-calendar" class="text-primary mr-4" size="20" />
        <h5 class="flex-grow-1">{{ getPontualTypeDateText(pontualType) }}</h5>
        <p class="ml-4">{{ getPontualTypeTimeText(pontualType) }}</p>
      </div>
    </div>
    <div class="px-3 pt-3 pb-4">
      <v-btn
        round
        block
        color="secondary"
        class="ml-0"
        dark
        @click="$emit('onClick', attributeOffer)"
      >
        <Icon name="fas fa-check-circle" class="mr-2" size="20" />Quero me
        inscrever
      </v-btn>
    </div>
    <div class="subs-box radius-0">
      <v-layout align-center justify-space-between flex-wrap>
        <strong>{{ totalRegistrationAmount }}/{{ attributeOffer.totalOfferAmount }}</strong>
      </v-layout>
    </div>
  </div>
</template>

<script>
import moment from "moment";
import VolunteeringRegistrationOfferService from "@/scripts/services/volunteeringRegistrationOffer.service";

export default {
  props: ["attributeOffer"],
  data() {
    return {
      volunteeringRegistrationOfferService: new VolunteeringRegistrationOfferService(),
      totalRegistrationAmount: 0
    };
  },
  watch: {
    attributeOffer(newValue, oldValue) {
      console.log(newValue);

      this.volunteeringRegistrationOfferService.findTotalRegistrationAmountByVolunteeringAttributeOfferId(newValue.id).then(data => {
        this.totalRegistrationAmount = data
      });
    }
  },
  mounted() {
    this.volunteeringRegistrationOfferService.findTotalRegistrationAmountByVolunteeringAttributeOfferId(this.attributeOffer.id).then(data => {
      this.totalRegistrationAmount = data
    });
  },
  methods: {

    getRecurrentWeekDayText(recurrentType) {
      var weekDays = [];
      recurrentType.volunteeringRecurrentWeekDays.forEach(weekDay => {
        if (weekDay.weekDayName == "Segunda") {
          weekDays.push({ order: 1, name: 'Seg' });
        }
        else if (weekDay.weekDayName == "Terça") {
          weekDays.push({ order: 2, name: 'Ter' });
        }
        else if (weekDay.weekDayName == "Quarta") {
          weekDays.push({ order: 3, name: 'Quar' });
        }
        else if (weekDay.weekDayName == "Quinta") {
          weekDays.push({ order: 4, name: 'Quinta' });
        }
        else if (weekDay.weekDayName == "Sexta") {
          weekDays.push({ order: 5, name: 'Sex' });
        }
        else if (weekDay.weekDayName == "Sábado") {
          weekDays.push({ order: 6, name: 'Sab' });
        }
        else if (weekDay.weekDayName == "Domingo") {
          weekDays.push({ order: 7, name: 'Dom' });
        }
      });

      if (weekDays.length == 0)
        return "Não definido";

      weekDays.sort((prev, next) => prev.order - next.order);

      if (this.weekDayHasGaps(weekDays))
        return this.getRecurrentWeekDayTextWithGaps(weekDays);

      return this.getRecurrentWeekDayTextWithoutGaps(weekDays);
    },
    weekDayHasGaps(weekDays) {
      let hasGap = false;
      let index = 0;
      let weekDayBefore = null;
      while (!hasGap && index < weekDays.length) {

        let weekDay = weekDays[index];
        if (weekDayBefore != null && weekDay.order - 1 != weekDayBefore.order)
          hasGap = true;

        weekDayBefore = weekDays[index];
        index++;
      }

      return hasGap;
    },
    getRecurrentWeekDayTextWithGaps(weekDays) {
      let result = ""
      for (let index = 0; index < weekDays.length; index++) {
        const weekDay = weekDays[index];
        result += weekDay.name;

        if (index + 2 == weekDays.length) {
          result += " e "
        }
        else if (index + 1 < weekDays.length) {
          result += ", "
        }
      }
      return result;
    },
    getRecurrentWeekDayTextWithoutGaps(weekDays) {
      if (weekDays.length == 0)
        return 'Não definido';

      if (weekDays.length > 1)
        return `${weekDays[0].name} à ${weekDays[weekDays.length - 1].name}`;

      return `${weekDays[0].name}`;
    },
    getRecurrentWeekHoursText(recurrentType) {
      if (recurrentType.minHoursDedication
        && recurrentType.maxHoursDedication
        && recurrentType.minHoursDedication != recurrentType.maxHoursDedication)
        return `De ${recurrentType.minHoursDedication} a ${recurrentType.maxHoursDedication} Horas Semanais`;

      if (recurrentType.minHoursDedication)
        return `${recurrentType.minHoursDedication} Horas Semanais`;

      if (recurrentType.maxHoursDedication)
        return `${recurrentType.maxHoursDedication} Horas Semanais`;

      return "Não definido";
    },
    getRecurrentWeekTimeText(recurrentType) {
      if (recurrentType.initialTime
        && recurrentType.finalTime
        && recurrentType.initialTime != recurrentType.finalTime)
        return `${recurrentType.initialTime}h às ${recurrentType.finalTime}h`;
      if (recurrentType.initialTime)
        return `às ${recurrentType.initialTime}h`;
      if (recurrentType.finalTime)
        return `às ${recurrentType.finalTime}h`;
    },    
    getRecurrentLimitDateText(recurrentType) {
      return moment(recurrentType.volunteeringDateUntil).format('DD/MM/YYYY');
    },
    getPontualTypeDateText(pontualType) {
      return moment(pontualType.volunteeringDate).format('DD/MM/YYYY');
    },
    getPontualTypeTimeText(pontualType) {
      if (pontualType.initialTime
        && pontualType.finalTime
        && pontualType.initialTime != pontualType.finalTime)
        return `${pontualType.initialTime}h às ${pontualType.finalTime}h`;
      if (pontualType.initialTime)
        return `às ${pontualType.initialTime}h`;
      if (recurrentType.finalTime)
        return `às ${pontualType.finalTime}h`;
    }
  }
};
</script>

<style>
</style>